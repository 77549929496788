export const REDUCERS = {
  AUTH: {
    CHECK_AUTHENTICATION_ERROR: 'auth/CHECK_AUTHENTICATION_ERROR',
    API_CHECK_AUTHENTICATION_REQUEST: 'auth/API_CHECK_AUTHENTICATION_REQUEST',
    API_CHECK_AUTHENTICATION_SUCCESS: 'auth/API_CHECK_AUTHENTICATION_SUCCESS',
  },
  APP: {
    RESET_ALL_STATE: 'app/RESET_ALL_STATE',
    SET_PRACTICE: 'app/SET_PRACTICE',
    SET_USER_DATA: 'app/SET_USER_DATA',
  },
  USER_MANAGEMENT: {
    API_DELETE_USER_REQUEST: 'userManagement/API_DELETE_USER_REQUEST',
    API_DELETE_USER_SUCCESS: 'userManagement/API_DELETE_USER_SUCCESS',
    API_DELETE_USER_ERROR: 'userManagement/API_DELETE_USER_ERROR',
    API_GET_USERS_REQUEST: 'userManagement/API_GET_USERS_REQUEST',
    API_GET_USERS_SUCCESS: 'userManagement/API_GET_USERS_SUCCESS',
    API_GET_USERS_ERROR: 'userManagement/API_GET_USERS_ERROR',
    API_POST_ADD_USER_REQUEST: 'userManagement/API_POST_ADD_USER_REQUEST',
    API_POST_ADD_USER_SUCCESS: 'userManagement/API_POST_ADD_USER_SUCCESS',
    API_POST_ADD_USER_ERROR: 'userManagement/API_POST_ADD_USER_ERROR',
    POST_ADD_USER_SUCCESS: 'userManagement/POST_ADD_USER_SUCCESS',
    POST_ADD_USER_ERROR: 'userManagement/POST_ADD_USER_ERROR',
    RESET_USER_CREATION_STATUS: 'userManagement/RESET_USER_CREATION_STATUS',
    RESET_USER_DELETE_STATUS: 'userManagement/RESET_USER_DELETE_STATUS',
  },
  DEMAND_MANAGEMENT: {
    UPDATE_ADMIN_AVAILABILITY: 'demandManagement/UPDATE_ADMIN_AVAILABILITY',
    API_GET_ADMIN_AVAILABILITY_REQUEST: 'demandManagement/API_GET_ADMIN_AVAILABILITY_REQUEST',
    API_GET_ADMIN_AVAILABILITY_SUCCESS: 'demandManagement/API_GET_ADMIN_AVAILABILITY_SUCCESS',
    API_GET_ADMIN_AVAILABILITY_ERROR: 'demandManagement/API_GET_ADMIN_AVAILABILITY_ERROR',
    API_POST_ADMIN_AVAILABILITY_REQUEST: 'demandManagement/API_POST_ADMIN_AVAILABILITY_REQUEST',
    API_POST_ADMIN_AVAILABILITY_SUCCESS: 'demandManagement/API_POST_ADMIN_AVAILABILITY_SUCCESS',
    API_POST_ADMIN_AVAILABILITY_ERROR: 'demandManagement/API_POST_ADMIN_AVAILABILITY_ERROR',
    SAVE_SCHEDULED_CLOSURE_REQUEST: 'demandManagement/SAVE_SCHEDULED_CLOSURE_REQUEST',
    SAVE_SCHEDULED_CLOSURE_SUCCESS: 'demandManagement/SAVE_SCHEDULED_CLOSURE_SUCCESS',
    SAVE_SCHEDULED_CLOSURE_ERROR: 'demandManagement/SAVE_SCHEDULED_CLOSURE_ERROR',
    GET_SCHEDULED_CLOSURES_REQUEST: 'demandManagement/GET_SCHEDULED_CLOSURES_REQUEST',
    GET_SCHEDULED_CLOSURES_SUCCESS: 'demandManagement/GET_SCHEDULED_CLOSURES_SUCCESS',
    GET_SCHEDULED_CLOSURES_ERROR: 'demandManagement/GET_SCHEDULED_CLOSURES_ERROR',
    DELETE_SCHEDULED_CLOSURES_REQUEST: 'demandManagement/DELETE_SCHEDULED_CLOSURES_REQUEST',
    DELETE_SCHEDULED_CLOSURES_SUCCESS: 'demandManagement/DELETE_SCHEDULED_CLOSURES_SUCCESS',
    DELETE_SCHEDULED_CLOSURES_ERROR: 'demandManagement/DELETE_SCHEDULED_CLOSURES_ERROR',
    GET_DEMAND_MANAGEMENT_ENABLED_REQUEST: 'demandManagement/GET_DEMAND_MANAGEMENT_ENABLED_REQUEST',
    GET_DEMAND_MANAGEMENT_ENABLED_SUCCESS: 'demandManagement/GET_DEMAND_MANAGEMENT_ENABLED_SUCCESS',
    GET_DEMAND_MANAGEMENT_ENABLED_ERROR: 'demandManagement/GET_DEMAND_MANAGEMENT_ENABLED_ERROR',
  },
  EMIS: {
    API_GET_EMIS_CREDENTIALS_REQUEST: 'emis/API_GET_EMIS_CREDENTIALS_REQUEST',
    API_GET_EMIS_CREDENTIALS_SUCCESS: 'emis/API_GET_EMIS_CREDENTIALS_SUCCESS',
    API_GET_EMIS_CREDENTIALS_ERROR: 'emis/API_GET_EMIS_CREDENTIALS_ERROR',
    API_POST_EMIS_CREDENTIALS_REQUEST: 'emis/API_POST_EMIS_CREDENTIALS_REQUEST',
    API_POST_EMIS_CREDENTIALS_SUCCESS: 'emis/API_POST_EMIS_CREDENTIALS_SUCCESS',
    API_POST_EMIS_CREDENTIALS_ERROR: 'emis/API_POST_EMIS_CREDENTIALS_ERROR',
    API_DELETE_EMIS_CREDENTIALS_REQUEST: 'emis/API_DELETE_EMIS_CREDENTIALS_REQUEST',
    API_DELETE_EMIS_CREDENTIALS_SUCCESS: 'emis/API_DELETE_EMIS_CREDENTIALS_SUCCESS',
    API_DELETE_EMIS_CREDENTIALS_ERROR: 'emis/API_DELETE_EMIS_CREDENTIALS_ERROR',
    CLEAR_EMIS_CREDENTIALS: 'emis/CLEAR_EMIS_CREDENTIALS',
  },
  FEATURE_FLAGS: {
    GET_FEATURE_FLAGS_REQUEST: 'featureFlags/GET_FEATURE_FLAGS_REQUEST',
    GET_FEATURE_FLAGS_SUCCESS: 'featureFlags/GET_FEATURE_FLAGS_SUCCESS',
    GET_FEATURE_FLAGS_ERROR: 'featureFlags/GET_FEATURE_FLAGS_ERROR',
  },
};
